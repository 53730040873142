import Header from '../components/Header';
import Footer from '../components/Footer';
import talent4 from '../images/talent-4.webp';
import company1 from '../images/company-1-white.webp';
import company2 from '../images/company-2-white.webp';
import company3 from '../images/company-3-white.webp';
import company4 from '../images/company-4-white.webp';
import company6 from '../images/company-6-white.webp';
import talent1 from '../images/talent-10.webp';
import talent2 from '../images/talent-2.webp';
import talent3 from '../images/talent-6.webp';
import talent6 from '../images/talent-7.webp';



const timeline = [
    {
        name: 'Founded company',
        description:
            'Our healthcare staffing company was founded with a vision to enhance healthcare practices by providing skilled professionals to providers.',
        date: 'Jan 2023',
        dateTime: '2023-01',
    },
    {
        name: 'Initial Launch',
        description:
            'Launched the early version of our innovative staffing platform, providing seamless connections between healthcare facilities and professionals.',
        date: 'Jul 2023',
        dateTime: '2023-07',
    },
    {
        name: 'Enhancement & Growth',
        description:
            'Enhanced customer experience based on user feedback and significantly expanded our team with top talent in technology and healthcare staffing.',
        date: 'Jan 2024',
        dateTime: '2024-01',
    },
    {
        name: 'Ongoing Expansion',
        description:
            'We\'ve continued growing and working to improve customer satisfaction.',
        date: 'Jun 2024',
        dateTime: '2024-06',
    },
];


export default function AboutPage() {

    return (
        <div className="bg-white">

            {/* HEADER */}
            <Header />

            <main className="isolate mb-32">
                {/* Hero section */}
                <div className="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
                    <div
                        className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-teal-900/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
                        aria-hidden="true"
                    />
                    <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
                            <h1 className="max-w-2xl text-4xl font-heading tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                                We’re your partner in healthcare efficiency.
                            </h1>
                            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                                <p className="text-lg leading-8 text-gray-600">
                                    At Janie, our mission is to revolutionize the way healthcare practices operate by providing top-notch remote office staffing solutions. We aim to reduce overhead costs, enhance patient experiences, and streamline the efficiency of medical practices, allowing onsite staff to focus on what truly matters: providing excellent patient care.
                                </p>
                            </div>
                            <img
                                src={talent4}
                                alt="Team"
                                className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
                            />
                        </div>
                    </div>
                    <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
                </div>

                {/* Timeline section */}
                <div className="mx-auto -mt-8 max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
                        {timeline.map((item) => (
                            <div key={item.name}>
                                <time
                                    dateTime={item.dateTime}
                                    className="flex items-center text-sm font-semibold leading-6 text-teal-900"
                                >
                                    <svg viewBox="0 0 4 4" className="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                                        <circle cx={2} cy={2} r={2} fill="currentColor" />
                                    </svg>
                                    {item.date}
                                    <div
                                        className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                                        aria-hidden="true"
                                    />
                                </time>
                                <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{item.name}</p>
                                <p className="mt-1 text-base leading-7 text-gray-600">{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Logo cloud */}
                <div className="mx-auto mt-32 max-w-7xl sm:mt-40 sm:px-6 lg:px-8">
                    <div className="relative isolate overflow-hidden bg-teal-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
                        <h2 className="mx-auto max-w-2xl text-3xl font-heading tracking-tight text-white sm:text-4xl">
                            Our customers are coming back for more.
                        </h2>
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                            Janie clients often end up circling back for additional services after having a great experience with our team!
                        </p>
                        <div className="mx-auto mt-20 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:max-w-4xl lg:grid-cols-5">
                            <img
                                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                                src={company1}
                                alt="Transistor"
                                width={158}
                                height={48}
                            />
                            <img
                                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                                src={company2}
                                alt="Reform"
                                width={158}
                                height={48}
                            />
                            <img
                                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                                src={company3}
                                alt="Tuple"
                                width={158}
                                height={48}
                            />
                            <img
                                className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                                src={company4}
                                alt="SavvyCal"
                                width={158}
                                height={48}
                            />
                            <img
                                className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                                src={company6}
                                alt="Statamic"
                                width={158}
                                height={48}
                            />
                        </div>
                    </div>
                </div>

                {/* Content section */}
                <div className="mt-32 overflow-hidden sm:mt-40">
                    <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
                        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                            <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                                <h2 className="text-3xl font-heading tracking-tight text-gray-900 sm:text-4xl">Our talent is top notch.</h2>
                                <p className="mt-6 text-xl leading-8 text-gray-600">
                                    Our team of experienced Remote Healthcare Assistants is rigorously trained and vetted to ensure they meet the highest standards.
                                </p>
                                <p className="mt-6 text-base leading-7 text-gray-600">
                                    Supported by practicing physicians, they are well-equipped to handle the daily demands of medical practices, helping to streamline processes and improve patient outcomes. Many of our staff come from medical school and healthcare practices themselves.
                                </p>
                            </div>
                            <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                                <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                                    <img
                                        src={talent1}
                                        alt=""
                                        className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                    />
                                </div>
                                <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                                    <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                                        <img
                                            src={talent6}
                                            alt=""
                                            className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                                        />
                                    </div>
                                    <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                                        <img
                                            src={talent3}
                                            alt=""
                                            className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                                        />
                                    </div>
                                    <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                                        <img
                                            src={talent2}
                                            alt=""
                                            className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Stats */}
                <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-3xl font-heading tracking-tight text-gray-900 sm:text-4xl">
                            We're passionate about enhancing modern practices while also improving your efficiency.
                        </h2>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Our team, clients, and talent are growing quickly. Your next hire is just a click away. Discover how Janie can help your practice grow by scheduling a consultation or learning more about our services. Let’s work together to enhance your practice’s efficiency and patient care.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
                        <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
                            <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">10K+</p>
                            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                                <p className="text-lg font-semibold tracking-tight text-gray-900">Global talent pool</p>
                                <p className="mt-2 text-base leading-7 text-gray-600">
                                    We leverage a talent pool exceeding 10,000 professionals.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-amber-50 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
                            <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">$80,000+</p>
                            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                                <p className="text-lg font-semibold tracking-tight text-gray-900">
                                    Average cost savings
                                </p>
                                <p className="mt-2 text-base leading-7 text-gray-900">
                                    Our client practices save over $80K on average, with just two hires.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-teal-900 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
                            <p className="flex-none text-3xl font-bold tracking-tight text-white">30+</p>
                            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                                <p className="text-lg font-semibold tracking-tight text-white">Practices served</p>
                                <p className="mt-2 text-base leading-7 text-white">
                                    We're growing quickly and adding practices every week.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

            {/* FOOTER */}
            <Footer />
        </div>
    )
}
