import Footer from '../components/Footer';
import { CurrencyDollarIcon, HeartIcon, CheckBadgeIcon } from '@heroicons/react/24/outline'
import { ComputerDesktopIcon, PhoneArrowUpRightIcon, BanknotesIcon, PencilIcon } from '@heroicons/react/24/outline'
import ContactForm from '../components/ContactForm';

import dog from '../images/dog.webp';
import dog2 from '../images/dog2.webp';
import janie from '../images/logo-white-margin.webp'


export default function VeterinaryPage() {

    const features = [
        {
            name: '$70K Avg. Savings',
            description:
                'Our solutions help veterinary practices save $70K+ annually, boosting your bottom line.',
            href: '#',
            icon: CurrencyDollarIcon,
        },
        {
            name: '97% Staff Retention',
            description:
                'Join the many satisfied practices enjoying a 97% staff retention rate with our services.',
            href: '#',
            icon: HeartIcon,
        },
        {
            name: '50+ Practices Served',
            description:
                'We are trusted by over 50 veterinary practices, ensuring you are in good company.',
            href: '#',
            icon: CheckBadgeIcon,
        },
    ]
    const stats = [
        { id: 1, name: 'Significant cost savings', value: 'By only charging $10-12 per hour, clients reduce labor costs by 50%+' },
        { id: 2, name: 'Tailored onboarding', value: 'Partner with us to create a custom implementation plan for your practice' },
        { id: 3, name: 'Qualified professionals', value: 'Each Janie associate is a trained veterinary professional' },
        { id: 4, name: 'Higher retention', value: 'No more rehiring and retraining; our average retention rate is 97%' },
        { id: 5, name: 'Activity monitoring', value: 'Capture daily and weekly team activities via productivity monitoring' },
        { id: 6, name: 'Multilingual team', value: 'Our global team offers patient translation in 5+ languages' },
    ]
    const supports = [
        {
            name: 'Front desk support',
            description:
                'Patient scheduling & reminders, general phone support, EHR data entry',
            icon: ComputerDesktopIcon,
        },
        {
            name: 'Client recall & outbound services',
            description:
                'Client recall, vaccination reminders, prescription requests',
            icon: PhoneArrowUpRightIcon,
        },
        {
            name: 'Payment & collections',
            description:
                'Payment posting, billing inquiries and reminders, collections',
            icon: BanknotesIcon,
        },
        {
            name: 'Remote scribing',
            description:
                'Charting, visit documentation, and scribing virtually',
            icon: PencilIcon,
        },
    ]

    return (
        <div className="bg-white">


            {/* HERO */}
            <div className="relative isolate overflow-hidden bg-teal-900 py-24 sm:pt-20 sm:pb-4">
                <div className="flex justify-center">
                    <img
                        src={janie}
                        alt="Janie"
                        className="w-36 h-auto"
                    />
                </div>
                <img
                    src={dog}
                    alt=""
                    className="absolute inset-0 -z-10 h-full w-full object-cover opacity-15"
                />
                <div className="px-6 py-10 sm:px-6 sm:py-32 lg:px-8">
                    <div className="mx-auto text-center">
                        <div className="mb-10 sm:flex justify-center">
                            <div className="relative rounded-full px-3 py-1 text-md leading-6 text-white ring-1 ring-white hover:ring-white">
                                Janie Health for <b>Veterinary Practices</b>
                            </div>
                        </div>
                        <h2 className="text-3xl tracking-tight text-white sm:text-6xl font-heading">
                            Get the support your practice needs
                        </h2>
                        <p className="mx-auto mt-6 max-w-xl sm:text-2xl text-lg text-white">
                            Janie helps veterinary practices and animal hospitals reduce costs and enhance efficiency.
                        </p>

                        <div className="mx-auto mt-16 max-w-2xl sm:mt-2 lg:mt-24 lg:max-w-6xl">
                            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                                {features.map((feature) => (
                                    <div key={feature.name} className="flex flex-col items-center">
                                        <dt className="flex flex-col items-center sm:text-2xl text-xl text-white">
                                            <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-white">
                                                <feature.icon aria-hidden="true" className="h-8 w-8 text-teal-900" />
                                            </div>
                                            {feature.name}
                                        </dt>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </div>

            {/* FORM */}
            <div id="form" className="isolate bg-white px-6 py-24 sm:py-32 lg:px-36">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 h-full lg:items-center">

                    {/* CONTACT */}
                    <ContactForm
                        title="See how Janie can transform your practice"
                        description="We'd love to get in touch and help you get started."
                        source="Veterinary Landing Page Lead Form"
                        lead={true}
                    />

                    {/* INFO */}
                    <div className="h-auto max-w-xl">
                        <div className="mx-auto">
                            {/* <h2 className="text-base font-semibold leading-8 text-teal-900">Get started today</h2> */}
                            <p className="mt-2 text-3xl font-heading tracking-tight text-gray-900 sm:text-4xl">
                                Our numerous client benefits
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Janie helps you significantly reduce overhead costs and increase staff retention, ensuring a stable and committed workforce, and promoting a healthier and more productive work environment for your practice.
                            </p>
                            <dl className="mt-16 grid max-w-xl grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xl:mt-16">
                                {stats.map((stat) => (
                                    <div key={stat.id} className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6">
                                        {/* <dt className="text-sm leading-6 text-gray-600">{stat.value}</dt> */}
                                        <dd className="order-first text-2xl tracking-tight text-gray-900">{stat.name}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>

                </div>
            </div>


            {/* TESTIMONIALS */}
            <section className="relative isolate overflow-hidden bg-teal-900 py-20 sm:py-24">
                <img
                    src={dog2}
                    alt=""
                    className="absolute inset-0 -z-10 h-full w-full object-cover opacity-20"
                />
                <div className="mx-auto max-w-7xl px-6 lg:px-8">

                    <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">

                        <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
                            <figure className="mt-10 flex flex-auto flex-col justify-between">
                                <blockquote className="font-heading text-3xl leading-8 text-white">
                                    <p>
                                        “We've worked with two other veterinary VA agencies and Janie is far and away the best.”
                                    </p>
                                </blockquote>
                                <figcaption className="mt-10 flex items-center gap-x-6">
                                    <div className="text-base">
                                        <div className="font-semibold text-white">Office Manager</div>
                                        <div className="mt-1 text-gray-400">CA</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="flex flex-col border-t border-white/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
                            <figure className="mt-10 flex flex-auto flex-col justify-between">
                                <blockquote className="font-heading text-3xl leading-8 text-white">
                                    <p>
                                        “Janie has helped us save thousands. We want at least 50% of our team to be from Janie over the next few months.”
                                    </p>
                                </blockquote>
                                <figcaption className="mt-10 flex items-center gap-x-6">
                                    <div className="text-base">
                                        <div className="font-semibold text-white">Veterinarian</div>
                                        <div className="mt-1 text-gray-400">FL</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>


            {/* FEATURES */}
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        {/* <h2 className="text-base font-semibold leading-7 text-teal-900">Our roles & capabilites</h2> */}
                        <p className="mt-2 text-2xl font-heading tracking-tight text-gray-900 sm:text-4xl">
                            How we can support your practice
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Janie provides staffing support for a variety of roles, as well as the ability to staff nearly any front or back office role you need filled. Contact us to learn more.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                            {supports.map((support) => (
                                <div key={support.name} className="relative pl-16">
                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-teal-900">
                                            <support.icon aria-hidden="true" className="h-6 w-6 text-white" />
                                        </div>
                                        {support.name}
                                    </dt>
                                    <dd className="mt-2 text-base leading-7 text-gray-600">{support.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>


            {/* CTA
            <div className="bg-teal-900 mb-24">
                <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
                    <h2 className="text-3xl font-heading tracking-tight text-white sm:text-4xl">
                        No long term commitments.
                        <br />
                        Schedule a call today.
                    </h2>
                    <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                        <a
                            href="#form"
                            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-teal-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-900"
                        >
                            Get started
                        </a>
                    </div>
                </div>
            </div> */}


            {/* FOOTER */}
            < Footer />
        </div>
    )
}