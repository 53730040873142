import logo from '../images/logo.webp';
import navigation from '../config/footer-navigation';

const Footer = () => {

    return (
        <footer className="bg-white">
            <div className="mx-auto max-w-7xl overflow-hidden px-6 pb-20 sm:pb-24 lg:px-8">
                <div className="flex justify-center">
                    <a href="/" className="p-1.5 pb-10">
                        <span className="sr-only">Janie</span>
                        <img alt="Janie" className="h-8" src={logo} />
                    </a>
                </div>
                <nav className="flex flex-col justify-center items-center space-y-4 sm:space-y-0 sm:flex-row sm:justify-center sm:space-x-12 mb-6" aria-label="Footer">
                    {navigation.main.map((item) => (
                        <div key={item.name} className="text-center">
                            <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                                {item.name}
                            </a>
                        </div>
                    ))}
                </nav>
                {/* <div className="mt-10 flex justify-center space-x-10">
                    {navigation.social.map((item) => (
                        <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">{item.name}</span>
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                        </a>
                    ))}
                </div> */}
                <p className="mt-10 text-center text-xs leading-5 text-gray-500">
                    &copy; {new Date().getFullYear()} Health Sphere Innovations, LLC. All rights reserved.
                </p>
            </div>
        </footer>
    )
};

export default Footer;