import Header from '../components/Header';
import Footer from '../components/Footer';
import { CheckIcon } from '@heroicons/react/20/solid'
import talent6 from '../images/talent-6.webp';
import talent8 from '../images/talent-8.webp';

const stats = [
    { name: 'Average cost savings', value: '$80K+' },
    { name: 'Gobal talent pool', value: '10K+' },
    { name: 'Practices served', value: '30+' },
    { name: 'Staff retention', value: '93%+' },
]

const steps = [
    {
        name: 'Understand and Customize',
        description:
            'We begin with a call to understand your needs, workflow challenges, and goals, then tailor our services accordingly.',
    },
    {
        name: 'Handpick and Integrate',
        description:
            'We select and match vetted Remote Healthcare Assistants to your needs, ensuring a smooth integration into your workflows.',
    },
    {
        name: 'Optimize and Support',
        description:
            'We provide ongoing support and performance monitoring, adapting our services as your practice evolves.',
    },
];


const benefits = [
    {
        name: 'Starting at $10 an hour',
        description: 'Our team members cost between $10 to $12 an hour depending on their experience. Save thousands of dollars by choosing a Remote Healthcare Assistant.',
    },
    {
        name: 'Part of your team',
        description: 'With average retention at 93%, our Remote Healthcare Assistants will integrate with your office and become long-term members of your staff. You\'ll work with the same person, every day.'
    },
    {
        name: 'HIPAA certified',
        description: 'All of our Remote Healthcare Assistants are HIPAA-certified and fully compliant. Our systems and processes are designed to protect patient data and privacy.',
    },
    {
        name: 'Use any EMR / EHR',
        description: 'Your Remote Healthcare Assistant will utilize the same tools, systems, and processes currently being used in your practice. They\'ll be onboarded to your office systems just like any other employee.',
    },
    {
        name: 'Medically trained professionals',
        description: 'All of our team members are licensed medical professionals with formal education and certifications in medicine, dentistry, nursing, physical therapy, medical coding, and more.'
    },
    {
        name: 'Grow your team quickly',
        description: 'We can have new staff members in your office in less than a week. Our goal is to help you scale (or downsize) your team as quickly as possible, depending on your needs.'
    },
    {
        name: 'No long-term commitment',
        description: 'Enjoy hassle-free monthly billing with no setup fees or long-term contracts. Our flexible billing process is tailored to meet your unique needs, allowing you the freedom to cancel at any time.',
    },
    {
        name: 'Best of the best',
        description: 'Our global recruiting team works tirelessly to find, screen, and train the best Remote Healthcare Assistants in the world. We prioritize integrity, work ethic, and kindness.',
    },
]

export default function HowItWorksPage() {

    return (
        <div className="bg-white">

            {/* HEADER */}
            <Header />

            {/* Heading */}
            <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 mt-24">
                <img
                    src={talent8}
                    alt=""
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
                />

                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-4xl font-heading tracking-tight text-white sm:text-6xl">How it works</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            At Janie, we are dedicated to transforming the way healthcare practices manage their operations. By leveraging our Remote Healthcare Assistants, you can reduce overhead costs and enhance patient experiences.
                        </p>
                    </div>
                    <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                            {stats.map((stat) => (
                                <div key={stat.name} className="flex flex-col-reverse">
                                    <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
                                    <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat.value}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

            {/* Steps */}
            <div className="overflow-hidden bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-8 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <h2 className="text-base font-semibold leading-7 text-teal-900">It's easy to start</h2>
                                <p className="mt-2 text-3xl font-heading tracking-tight text-gray-900 sm:text-4xl">Our process</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    We provide a high-touch, transparent service. Here’s how our process works in just three easy steps.
                                </p>
                                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                    {steps.map((feature) => (
                                        <div key={feature.name} className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-900">
                                                {feature.name}
                                            </dt>
                                            <br></br>
                                            <dd className="inline">{feature.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                        <img
                            src={talent6}
                            alt="Product screenshot"
                            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                            width={2432}
                            height={1442}
                        />
                    </div>
                </div>
            </div>

            {/* Benefits */}
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">

                        <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                            {benefits.map((feature) => (
                                <div key={feature.name} className="relative pl-9">
                                    <dt className="font-semibold text-gray-900">
                                        <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-teal-500" aria-hidden="true" />
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-2">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                        <div>
                            <h2 className="text-base sm:text-right font-semibold leading-7 text-teal-900">Low cost, highly beneficial</h2>
                            <p className="mt-2 text-3xl sm:text-right font-heading tracking-tight text-gray-900 sm:text-4xl">Our client benefits</p>
                            <p className="mt-6 text-base sm:text-right leading-7 text-gray-600">
                                Solve your practice office staffing problems with our fully managed approach to staffing.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* CTA */}
            <div className="bg-teal-900 mb-24">
                <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
                    <h2 className="text-3xl font-heading tracking-tight text-white sm:text-4xl">
                        No long term commitments.
                        <br />
                        Schedule a call today.
                    </h2>
                    <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                        <a
                            href="https://calendly.com/brigham-janiehealth/30min"
                            target='_blank'
                            rel="noreferrer"
                            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-teal-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-900"
                        >
                            Get started
                        </a>
                        <a href="/services" className="text-sm font-semibold leading-6 text-white">
                            Learn more <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
            </div>


            {/* FOOTER */}
            <Footer />
        </div>
    )
}
