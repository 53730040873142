import hero from '../images/hero.webp';
import company1 from '../images/company-1.webp';
import company2 from '../images/company-2.webp';
import company3 from '../images/company-3.webp';
import company4 from '../images/company-4.webp';
import company5 from '../images/company-5.webp';
import company6 from '../images/company-6.webp';
import company7 from '../images/company-7.webp';
import company8 from '../images/company-8.webp';
import company9 from '../images/company-9.webp';
import company10 from '../images/company-10.webp';
import company11 from '../images/company-11.webp';

import './LandingPage.css';
import { CheckIcon } from '@heroicons/react/20/solid'
import Header from '../components/Header';
import Footer from '../components/Footer';

const features = [
    {
        name: 'Medical',
        description:
            'Help your front desk staff focus on providing excellent patient care, let us handle the rest.',
    },
    {
        name: 'Dental',
        description:
            'From insurance verification to patient recall services and scheduling, we\'ve got you covered.',
    },
    {
        name: 'Veterinary',
        description:
            'Transform your veterinary practice with experienced Remote Veterinary Assistants.',
    },
    {
        name: 'Pharmacy',
        description:
            'Our Remote Pharmacy Assistants will handle customer calls and save your team hours each week.',
    },
]

const features2 = [
    {
        name: 'Starting at $10 an hour',
        description: 'Our team members cost between $10 to $12 an hour depending on their experience. Save thousands of dollars by choosing a Remote Healthcare Assistant',
    },
    {
        name: 'Part of your team',
        description: 'With average retention at 93%, our Remote Healthcare Assistants will integrate with your office and become long-term members of your staff. You\'ll work with the same person, every day.'
    },
    {
        name: 'HIPAA certified',
        description: 'All of our Remote Healthcare Assistants are HIPAA-certified and fully compliant. Our systems and processes are designed to protect patient data and privacy.',
    },
    {
        name: 'Use any EMR / EHR',
        description: 'Your Remote Healthcare Assistant will utilize the same tools, systems, and processes currently being used in your practice. They\'ll be onboarded to your office systems just like any other employee.',
    },
    {
        name: 'Medically trained professionals',
        description: 'All of our team members are licensed medical professionals with formal education and certifications in medicine, dentistry, nursing, physical therapy, medical coding, and more.'
    },
    {
        name: 'Grow your team quickly',
        description: 'We can have new staff members in your office in less than a week. Our goal is to help you scale (or downsize) your team as quickly as possible, depending on your needs.'
    },
    {
        name: 'No long-term commitment',
        description: 'Enjoy hassle-free monthly billing with no setup fees or long-term contracts. Our flexible billing process is tailored to meet your unique needs, allowing you the freedom to cancel at any time.',
    },
    {
        name: 'Best of the best',
        description: 'Our global recruiting team works tirelessly to find, screen, and train the best Remote Healthcare Assistants in the world. We prioritize integrity, work ethic, and kindness.',
    },
]

const logos = [
    company1,
    company2,
    company3,
    company4,
    company5,
    company6,
    company7,
    company8,
    company9,
    company10,
    company11
];



export default function LandingPage() {

    return (
        <div className="bg-white">

            {/* HEADER */}
            <Header />

            {/* HERO */}
            <div className="relative">
                <div className="mx-auto max-w-7xl">
                    <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
                        <svg
                            className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                            aria-hidden="true"
                        >
                            <polygon points="0,0 90,0 50,100 0,100" />
                        </svg>

                        <div className="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-56 lg:pr-0">
                            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                                <div className="hidden sm:mb-10 sm:flex">
                                    <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                                        See how we save practices over $100K.{' '}
                                        <a href="/howitworks" className="whitespace-nowrap font-semibold text-teal-900">
                                            <span className="absolute inset-0" aria-hidden="true" />
                                            Read more <span aria-hidden="true">&rarr;</span>
                                        </a>
                                    </div>
                                </div>
                                <h1 className="text-4xl font-heading font-regular tracking-tight text-gray-900 sm:text-6xl">
                                    Remote office staffing for your healthcare practice
                                </h1>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Janie is the leading solution for Remote Healthcare Assistants.
                                    Reduce overhead costs, enhance patient experience, and streamline your practice with Remote Healthcare Assistants.
                                </p>
                                <div className="mt-10 flex items-center gap-x-6">
                                    <a
                                        href="https://calendly.com/brigham-janiehealth/30min"
                                        target='_blank'
                                        rel="noreferrer"
                                        className="rounded-md bg-teal-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-900"
                                    >
                                        Get started
                                    </a>
                                    <a href="/services" className="text-sm font-semibold leading-6 text-gray-900">
                                        Learn more <span aria-hidden="true">→</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <img
                        className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full h-[400px] object-top"
                        src={hero}
                        alt=""
                    />
                </div>
            </div>

            {/* FEATURES */}
            <div className="bg-teal-900 py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-3xl font-heading font-regular tracking-tight text-white sm:text-4xl">Grow your team and your bottom line</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            Janie helps medical practices and healthcare groups cut costs and enhance efficiency, all so that onsite staff can focus on what matters most: providing excellent patient care.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4">
                            {features.map((feature) => (
                                <div key={feature.name} className="flex flex-col">
                                    <dt className="text-base font-semibold leading-7 text-white">
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                                        <p className="flex-auto">{feature.description}</p>
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

            {/* LOGOS */}
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto px-6 lg:px-8">
                    <h2 className="text-center text-2xl font-heading font-regular leading-8 text-gray-900">
                        Backed by practicing physicians
                    </h2>
                    <div className="sliding-container mx-auto mt-10 max-w-lg sm:max-w-xl lg:mx-0 lg:max-w-none">
                        <div className="sliding-wrapper">
                            {logos.map((logo, index) => (
                                <img
                                    key={index}
                                    src={logo}
                                    className="mx-6"
                                    style={{ height: '50px', width: 'auto' }}
                                    alt={`Logo ${index + 1}`}
                                />
                            ))}
                            {logos.map((logo, index) => (
                                <img
                                    key={index + logos.length} // Ensure a unique key for each duplicated logo
                                    src={logo}
                                    className="mx-6"
                                    style={{ height: '50px', width: 'auto' }}
                                    alt={`Logo ${index + 1}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* CTA */}
            <div className="bg-teal-900">
                <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-heading tracking-tight text-white sm:text-4xl">
                            Add talent to your team.
                            <br />
                            Boost your bottom line.
                        </h2>
                        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-white">
                            Janie is supported by a team of practicing physicians to ensure that our Remote Healthcare Assistants are fully prepared to meet the needs of today’s physicians and practice managers.
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href="https://calendly.com/brigham-janiehealth/30min"
                                target='_blank'
                                rel="noreferrer"
                                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-teal-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >
                                Get started
                            </a>
                            <a href="/services" className="text-sm font-semibold leading-6 text-white">
                                Learn more <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/* FEATURES 2 */}
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        <div>
                            <h2 className="text-4xl font-heading leading-7 text-teal-900">Specific to your needs</h2>
                            <p className="mt-2 text-3xl font-heading font-bold tracking-tight text-gray-900 sm:text-4xl">Benefits for your practice</p>
                            <p className="mt-6 text-base leading-7 text-gray-600">
                                Since day one, teams of physicians have leveraged Janie’s Remote Healthcare Assistants and the impact on their onsite staff, patient experience, and bottom line has been significant.
                            </p>
                        </div>
                        <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                            {features2.map((feature) => (
                                <div key={feature.name} className="relative pl-9">
                                    <dt className="font-semibold text-gray-900">
                                        <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-teal-900" aria-hidden="true" />
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-2">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

            {/* TESTIMONIALS */}
            <section className="bg-teal-900 py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
                            <figure className="mt-10 flex flex-auto flex-col justify-between">
                                <blockquote className="text-lg leading-8 text-white">
                                    <p>
                                        "I couldn’t have been more impressed with all three candidates we met!"
                                    </p>
                                </blockquote>
                                <figcaption className="mt-10 flex items-center gap-x-6">
                                    <div className="text-base">
                                        <div className="font-semibold text-white">Chandler</div>
                                        <div className="mt-1 text-gray-400">Huntsman Spine</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="flex flex-col border-t border-white/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
                            <figure className="mt-10 flex flex-auto flex-col justify-between">
                                <blockquote className="text-lg leading-8 text-white">
                                    <p>
                                        "All of the billing candidates exceeded my expectations. We've been amazed by Janie so far and are excited for Diana to hit the ground running."
                                    </p>
                                </blockquote>
                                <figcaption className="mt-10 flex items-center gap-x-6">
                                    <div className="text-base">
                                        <div className="font-semibold text-white">Alexis</div>
                                        <div className="mt-1 text-gray-400">Fillmore Eye Clinic</div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA 2 */}
            <div className="bg-white">
                <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
                    <h2 className="text-4xl font-heading tracking-tight text-gray-900 sm:text-4xl">
                        Your next hire is just a click away.
                        <br />
                        Get started today.
                    </h2>
                    <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                        <a
                            href="https://calendly.com/brigham-janiehealth/30min"
                            target='_blank'
                            rel="noreferrer"
                            className="rounded-md bg-teal-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-900"
                        >
                            Get started
                        </a>
                        <a href="/howitworks" className="text-sm font-semibold leading-6 text-gray-900">
                            Learn more <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <Footer />
        </div>
    )
}
