import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import ServicesPage from './pages/ServicesPage';
import HowItWorksPage from './pages/HowItWorksPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import AboutPage from './pages/AboutPage';
import PricingPage from './pages/PricingPage';
import VeterinaryPage from './pages/VeterinaryPage';
import DentistAdvisorsReferralPage from './pages/partners/DentistAdvisorsReferralPage';
import DentalIntellligenceReferralPage from './pages/partners/DentalIntelligenceReferralPage';
import Page404 from './pages/Page404';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/howitworks" element={<HowItWorksPage />} />
        <Route path="/veterinary" element={<VeterinaryPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/terms" element={<TermsOfServicePage />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/partners/dentist-advisors" element={<DentistAdvisorsReferralPage />} />
        <Route path="/partners/dental-intelligence" element={<DentalIntellligenceReferralPage />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
